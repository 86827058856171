import React from "react"

import "./praise-list.scss"

export default function Praise() {
  var styles = {
    allison: {
      backgroundImage: 'url("/images/praise/allison.jpg")',
    },
    anne: {
      backgroundImage: 'url("/images/praise/anne.jpg")',
    },
    cindy: {
      backgroundImage: 'url("/images/praise/cindy.jpg")',
    },
    camille: {
      backgroundImage: 'url("/images/praise/camille.jpg")',
    },
    melodie: {
      backgroundImage: 'url("/images/praise/melodie.jpg")',
    },
  }

  return (
    <div className="at-container">
      <div className="at-pagewrapper at-pagewrapper--sboff">
        <main className="at-page" role="main">
          <article>
            <div className="at-testimonals2">
              <ul className="at-testimonals2__list">
                <li>
                  <div className="at-testimonial2 at-testimonial2--hasimage">
                    <div
                      className="at-testimonial2__image"
                      style={styles.melodie}
                    ></div>
                    <div className="at-testimonial2__contentwrap">
                      <div className="at-testimonial2__content">
                        <p>
                          Diane m'a accompagné pendant plusieurs semaines pour
                          m'aider à approfondir un coaching de groupe. Elle m'a
                          aidé à me défaire de plusieurs croyances limitantes et
                          à m'accepter et ses exercices ont su nourrir ma
                          démarche et ma réflexion. Diane est d'une grande
                          bienveillance et d'une grande empathie. C'est aussi
                          une coach compétente, flexible et disponible et plus
                          généralement une personne souriante, honnête et très
                          positive. Vous pouvez lui faire confiance les yeux
                          fermés, vous ne le regretterez pas !
                        </p>
                        <span className="at-testimonial2__author">Mélodie</span>
                        <span className="at-testimonial2__authordetails"></span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="at-testimonial2 at-testimonial2--hasimage">
                    <div
                      className="at-testimonial2__image"
                      style={styles.cindy}
                    ></div>
                    <div className="at-testimonial2__contentwrap">
                      <div className="at-testimonial2__content">
                        <p>
                          Diane m'accompagne maintenant depuis plusieurs
                          semaines dans ma transition de vie et transition
                          professionnelle. Son aide m'est d'un grand soutien et
                          m'apporte beaucoup. Elle est toujours disponible, même
                          en dehors de nos sessions de coaching. Je me sens
                          vraiment outillée et guidée dans mon cheminement. De
                          plus elle a toujours le mot pour remonter le moral
                          quand ça va moins bien. C'est précieux ! Merci pour
                          ton temps et ton support Diane !
                        </p>
                        <span className="at-testimonial2__author">Cindy</span>
                        <span className="at-testimonial2__authordetails"></span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="at-testimonial2 at-testimonial2--hasimage">
                    <div
                      className="at-testimonial2__image"
                      style={styles.camille}
                    ></div>
                    <div className="at-testimonial2__contentwrap">
                      <div className="at-testimonial2__content">
                        <p>
                          Je recommande à 110%. Diane est d'une écoute, d'un
                          pétillement et d'une humanité sans pareille. Diane
                          sait comment guider un questionnement et poser les
                          bonnes interrogations. Elle m'a aidé dans ma quête de
                          devenir une meilleure et plus heureuse “moi”. Vous
                          pouvez lui faire confiance les yeux fermés.
                        </p>
                        <span className="at-testimonial2__author">Camille</span>
                        <span className="at-testimonial2__authordetails"></span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="at-testimonial2 at-testimonial2--hasimage">
                    <div
                      className="at-testimonial2__image"
                      style={styles.allison}
                    ></div>
                    <div className="at-testimonial2__contentwrap">
                      <div className="at-testimonial2__content">
                        <p>
                          J'ai fait une séance avec Diane car j'avais besoin de
                          me situer dans ma vie. Elle m'a écouté avec beaucoup
                          de bienveillance et a su me poser des questions très
                          pertinentes qui m'ont aidées à emprunter des pistes de
                          réflexion. Son ouverture et sa compassion apaisent les
                          maux et poussent vers le haut. Tout est facile avec
                          Diane, et sa joie de vivre est communicative !
                        </p>
                        <span className="at-testimonial2__author">Allison</span>
                        <span className="at-testimonial2__authordetails"></span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="at-testimonial2 at-testimonial2--hasimage">
                    <div
                      className="at-testimonial2__image"
                      style={styles.anne}
                    ></div>
                    <div className="at-testimonial2__contentwrap">
                      <div className="at-testimonial2__content">
                        <p>
                          J'ai rencontré Diane à un moment de ma vie
                          professionnelle où tout était embrouillé. Je ne savais
                          pas ce qui méritait que je m'y attarde, ce que je
                          recherchais pour ma carrière et ce que je pouvais
                          faire de mon bagage. Dans une approche conviviale,
                          presque informelle, elle m'a guidé par des questions
                          judicieuses pour faire un portrait réaliste de qui je
                          suis, ce que je veux, ce que je peux accomplir et du
                          plan pour y arriver.
                        </p>
                        <p>
                          Diane sait poser les bonnes questions au bon moment,
                          relancer et creuser quand elle perçoit qu'on peut
                          aller plus loin, sans jamais mettre de pression. Elle
                          a un excellent flair et beaucoup d'instinct, et a su
                          me faire prendre conscience de projets qui
                          sommeillaient en moi, tout en me donnant des idées
                          concrètes pour les réaliser. Je suis sortie de la
                          rencontre en paix avec mes projets un peu fous, et
                          encore à ce jour, des mois plus tard, je sais qu'on a
                          déniché ensemble mon idéal de vie.
                        </p>
                        <span className="at-testimonial2__author">Anne</span>
                        <span className="at-testimonial2__authordetails"></span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </article>
        </main>
      </div>
    </div>
  )
}
