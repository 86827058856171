import React from "react"
import { Link } from "gatsby"

import Header from "../components/header/header"
import Navigation from "../components/navigation/navigation"
import SocialFooter from "../components/social-footer/social-footer"
import Footer from "../components/footer/footer"
import PageHeader from "../components/page-header/page-header"
import PraiseList from "../components/praise-list/praise-list"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

export default function Contact() {
  return (
    <>
      <SEO title={"Témoignages"} keywords={[`avis`, `témoignages`]} />
      <div className="at-sitecontainer at-sitecontainer--wide">
        <Header />
        <Navigation />
        <PageHeader title="Témoignages" header="/images/headers/5.jpg" />
        <Layout>
          <article>
            <div className="at-page__content">
              <h2 class="at-page__title">
                Ces personnes, TOUTES INSPIRANTES !
              </h2>
              <div class="at-page__title-divider"></div>
              <p>
                Est-ce que tu t'es déjà demandé.e si dans ce monde et dans
                l’histoire de l’humanité, il y avait une autre personne dans la
                même situation que toi, avec les mêmes challenges et défis à
                relever, avec les mêmes limites que tu rencontres et qui a
                réussi à atteindre ses objectifs ? Bien sûr que oui ! Et si ces
                personnes inspirantes ont réussi à changer leur vie, tu le peux
                aussi grâce au développement personnel !
              </p>
              <p>
                Mes inspirant.e.s ont accepté de te partager leur parcours et
                leurs expériences profondément transformatrices suite à nos
                quelques et riches rencontres en coaching.
              </p>
              <p>
                Toi aussi tu veux atteindre ce tel niveau d’aspiration, en
                commençant par t’inspirer toi-même pour plus tard inspirer
                d’autres personnes ? Alors je t’invite à réserver dès maintenant
                un appel d’introspection avec moi, coach de vie, afin de
                découvrir si ensemble nous pouvons t’élever au rang que tu
                mérites et à devenir cette personne inspirée et inspirante.
              </p>
              <div className="centered">
                <Link to="/contact">
                  <button type="submit" className="primary">
                    Prendre ce premier rendez-vous
                  </button>
                </Link>
              </div>
            </div>
          </article>
          <PraiseList />
        </Layout>
        <SocialFooter />
        <Footer />
      </div>
    </>
  )
}
